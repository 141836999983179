import React, {Component} from 'react';
import logo from './cooking-pot.gif';
import namelogo from './namelogo2.png';
import './App.css';

export default class App extends Component {

	constructor(props) {
        super(props)
        this.state = {
			x:window.innerHeight,
			y:window.innerWidth,
			dpr:window.devicePixelRatio,
			useMinWidth:false
        }
    }

	componentDidMount() {
		window.addEventListener('resize', this.updateSize);
		if(window.innerHeight > window.innerWidth && ((window.innerHeight > 1280 && window.innerWidth > 720) || this.state.dpr>1.9)){
			this.setState({useMinWidth:true});
		}
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateSize);
	}

	updateSize = () => {
		if(window.innerHeight > window.innerWidth && ((window.innerHeight > 1280 && window.innerWidth > 720) || this.state.dpr>1.9)){
			this.setState({useMinWidth:true});
		}
		else{
			this.setState({useMinWidth:false});
		}
		this.setState({x:window.innerHeight, y:window.innerWidth, dpr:window.devicePixelRatio});
	}

    render() {
        return(
			<div className="App-header">
				<img src={namelogo} className="photo2" alt="coming soon..."  />
				
				<span className="font-link">
				"I don't want to be at the mercy of my emotions. I want to use them, to enjoy them, and to dominate them."
				</span>
				<span className="font-link">
				― Oscar Wilde
				</span>
				<img src={logo} className="photo" alt="coming soon..." style={{position:'absolute', bottom: 10, right: 10, minWidth : this.state.useMinWidth ? '20%':'64px' }} />
			</div>

			
        );
    }

	/*const [size, setSize] : useState({
		x: window.innerWidth,
		y: window.innerHeight
	});
	const updateSize = () =>
	setSize({
		x: window.innerWidth,
		y: window.innerHeight
	});
	useEffect(() => (window.onresize = updateSize), []);

	return(
		<div>
			<Routes>
                 <Route exact path='/' element={<LandingPage/>} >
				 <LandingPage/>
					 </Route>
          </Routes>
		</div>
	);
*/
	//return(
	//	<div className="App-header">
	//		<img src={namelogo} className="photo2" alt="coming soon..."  />
	//		<img src={logo} className="photo" alt="coming soon..." style={{position:'absolute', bottom: 10, right: 10}} />
	//	</div>
	//);
	//return (
	//	<div className="App">
	//		<body className="App-header">
	//			<p>
	//				CRIMSON CHASE
	//			</p>
	//			<img src={logo} className="photo" alt="coming soon..." style={{alignSelf: 'flex-end', bottom: 10, right: 10}} />
	//		</body>
	//	</div>
  	//);
}

